import { Controller } from '@hotwired/stimulus';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte

export default class extends Controller {
  connect() {
    const n = new Notyf();
    const errorTypes = ['error', 'warning', 'danger'];
    const { flashType, flashMessage } = this.element.dataset;
    const notyfType = errorTypes.includes(flashType.toLowerCase())
      ? 'error'
      : 'success';
    n[notyfType](flashMessage);
  }
}
