// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails');

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log(
  'Visit the guide for more information: ',
  'https://vite-ruby.netlify.app/guide/rails'
);

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import '@hotwired/turbo-rails';
import '@appstack/src/js/app';

// manually initialise stimulus
import '../controllers';

document.addEventListener("keydown", function(event) {
  console.log(event.key )
  if (event.key === "ArrowRight") {
    const rightArrowSubmitButton = document.querySelector('button[type="submit"].btn.left-right');
    if (rightArrowSubmitButton) { 
      event.preventDefault(); 
      console.log("rightArrowSubmitButton exists!");
      rightArrowSubmitButton.click();
    }  

  }  else if (event.key === "ArrowLeft") {
    const lefttArrowSubmitButton = document.querySelector('button[type="submit"].btn.left-left');
    if (lefttArrowSubmitButton) { 
      event.preventDefault(); 
      console.log("lefttArrowSubmitButton exists!");
      lefttArrowSubmitButton.click();
    }  
  } else if (event.key === "Escape") {
    const escapeButton = document.querySelector('.escape-key');
    if (escapeButton) { 
      event.preventDefault(); 
      console.log("escapeButton exists!");
      escapeButton.click();
    } 

  }
});
